html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}
